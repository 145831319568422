import { useModal } from 'components/Modal';
import type { KPIType } from 'dynamo-wrapper';
import { useState } from 'react';

const useKPITypeSelection = () => {
    const [selectedType, setSelectedType] = useState<KPIType | undefined>();
    const addEditModal = useModal();
    const deleteModal = useModal();

    const handleEdit = (type: KPIType) => {
        setSelectedType(type);
        addEditModal.open();
    };

    const handleDelete = (type: KPIType) => {
        setSelectedType(type);
        deleteModal.open();
    };

    const resetSelection = () => {
        setSelectedType(undefined);
    };

    return {
        selectedType,
        addEditModal,
        deleteModal,
        handleEdit,
        handleDelete,
        resetSelection
    };
};

export default useKPITypeSelection;
