import { isFeatureEnabled } from '@squaredup/tenants';
import { useTier } from 'queries/hooks/useTier';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { KPI_TYPES, ListTypes } from 'services/KPIService';

export const useKPITypes = () => {
    const { data: tier, isLoading: isTierLoading } = useTier();
    const isFeatureAvailable = tier !== undefined && isFeatureEnabled(tier, 'kpiTypes');
    const queryClient = useQueryClient();

    const { isLoading: isKPITypesLoading, data: types } = useQuery(KPI_TYPES, ListTypes);

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries(KPI_TYPES);
    }, [queryClient]);

    return {
        isLoading: isKPITypesLoading || isTierLoading,
        types,
        isFeatureAvailable,
        invalidate
    };
};
