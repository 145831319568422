import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import type { KPIType } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { useMutation, useQueryClient } from 'react-query';
import { CreateType, KPI_TYPES, UpdateType } from 'services/KPIService';

const description = (
    <>
        KPI types are a general classification for key metrics you want to publish (e.g. Cost, MTTR, SLI).{' '}
        <a
            href='https://docs.squaredup.com/first-steps/kpis#adding-kpi-types'
            target='_blank'
            rel='noreferrer noopener'
            className='text-textLink'
        >
            Learn more
        </a>{' '}
    </>
);

interface KPITypeModalProps {
    type?: KPIType;
    close: () => void;
    existingTypes: KPIType[];
}

interface FormData {
    displayName: string;
}

function KPITypeModal({ type, close, existingTypes }: KPITypeModalProps) {
    const queryClient = useQueryClient();

    const defaultValues = {
        displayName: type?.displayName
    };

    const createMutation = useMutation((displayName: string) => CreateType(displayName), {
        onSuccess: (_, displayName) => {
            trackEvent('KPI Type Created', { name: displayName });
            close();
        },
        onSettled: () => {
            queryClient.invalidateQueries(KPI_TYPES);
        }
    });

    const updateMutation = useMutation(
        ({ id, displayName }: { id: string; displayName: string }) => UpdateType(id, displayName),
        {
            onSuccess: () => {
                close();
            },

            onSettled: () => {
                queryClient.invalidateQueries(KPI_TYPES);
            }
        }
    );

    const handleSubmit = async (data: FormData) => {
        const { displayName } = data;

        if (type && type.id) {
            updateMutation.mutate({ id: type.id, displayName });
        } else {
            createMutation.mutate(displayName);
        }
    };

    const validateDuplicateName = (value: string) => {
        // If editing existing type, exclude current type from duplicate check
        const otherTypes = type ? existingTypes.filter((t) => t.id !== type.id) : existingTypes;

        const isDuplicate = otherTypes.some((t) => t.displayName.toLowerCase() === value.toLowerCase());

        return isDuplicate ? 'A KPI type with this name already exists' : true;
    };

    return (
        <Modal
            title={type ? `Edit KPI type: ${type.displayName}` : 'Add KPI type'}
            close={close}
            description={description}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <Form submit={handleSubmit} defaultValues={defaultValues} className='flex flex-col flex-1 min-h-0'>
                {(isValid, isSubmitting) => (
                    <>
                        <div className='px-8 pt-8 tile-scroll-overflow'>
                            <Field.Input
                                name='displayName'
                                label='Name'
                                title='Name'
                                placeholder='Enter a name'
                                validation={{
                                    required: true,
                                    maxLength: { value: 128, message: 'KPI Type Name is too long.' },
                                    minLength: { value: 1, message: 'KPI Type Name is too short.' },
                                    validate: validateDuplicateName
                                }}
                            />
                        </div>

                        <ModalButtons>
                            <Button type='button' onClick={close} variant='tertiary' data-testid='cancelKPIType'>
                                Cancel
                            </Button>

                            <Button
                                type='submit'
                                disabled={
                                    isSubmitting || !isValid || createMutation.isLoading || updateMutation.isLoading
                                }
                                data-testid='submitKPIType'
                            >
                                {createMutation.isLoading || updateMutation.isLoading ? (
                                    <LoadingSpinner size={18} />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </ModalButtons>
                    </>
                )}
            </Form>
        </Modal>
    );
}

export default KPITypeModal;
