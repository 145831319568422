import { cn } from '@/lib/cn';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

interface DataTableProps {
    config?: DataStreamBaseTileConfig;
    rawHeaderLabels?: boolean;
    className?: string;
}

export const DataTable: React.FC<DataTableProps> = ({ config, className, rawHeaderLabels }) => {
    const { tileConfig } = useTileEditorContext();
    const tableConfig = config ?? tileConfig;

    return (
        <div className={cn('h-full', className)}>
            <DataStreamBaseTile
                config={{
                    ...tableConfig,
                    visualisation: {
                        type: 'data-stream-table',
                        config: {
                            'data-stream-table': {
                                showShapeInTooltip: true,
                                useAccessorHeaderLabels: rawHeaderLabels
                            }
                        }
                    }
                }}
            />
        </div>
    );
};
