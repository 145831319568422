import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import stringify from 'fast-json-stable-stringify';
import { range } from 'lodash';
import { useMemo, useState } from 'react';
import { Tabs } from 'ui/Tabs';
import { TileWarnings } from 'ui/tile/TileWarnings';
import { useDatasetContext } from '../contexts/DatasetContext';
import { useTileEditorContext } from '../contexts/TileEditorContext';
import { DataTable } from './preview/DataTable';
import { UnshapedDataOption } from './preview/UnshapedDataOption';
import { UnshapedDataTable } from './preview/UnshapedDataTable';
import { VisualisationPreview } from './preview/VisualisationPreview';

const previewTab = {
    label: 'Visualization',
    component: <VisualisationPreview />
};

const nonDatasetModeTabs = (isShowingUnshapedData: boolean) => [
    previewTab,
    {
        label: 'Data',
        component: <div className='h-full p-4'>{isShowingUnshapedData ? <UnshapedDataTable /> : <DataTable />}</div>
    }
];

/**
 * @returns Visualization preview panel
 */
export const DataStreamTileEditorPreview = () => {
    const { isDatasetMode, tileConfig } = useTileEditorContext();
    const { datasets, activePreviewTab, setActivePreviewTab } = useDatasetContext();

    const [isShowingUnshapedData, setIsShowingUnshapedData] = useState(false);

    const tabs = useMemo(() => {
        if (!isDatasetMode) {
            return nonDatasetModeTabs(isShowingUnshapedData);
        }

        return [
            previewTab,
            ...datasets.map(({ tableName, config }) => ({
                label: tableName,
                component: isShowingUnshapedData ? (
                    <UnshapedDataTable
                        key={tableName}
                        className='p-4'
                        config={config as DataStreamBaseTileConfig}
                        rawHeaderLabels={true}
                    />
                ) : (
                    <DataTable
                        key={tableName}
                        className='p-4'
                        config={config as DataStreamBaseTileConfig}
                        rawHeaderLabels={true}
                    />
                )
            })),
            {
                label: 'Output',
                component: <DataTable key='output' className='p-4' />
            }
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringify(datasets), isDatasetMode, isShowingUnshapedData]);

    const isDataTab =
        (isDatasetMode && range(1, datasets.length + 1).includes(activePreviewTab)) ||
        (!isDatasetMode && activePreviewTab !== 0);

    const datasetConfig = datasets[activePreviewTab - 1]?.config as DataStreamBaseTileConfig;

    return (
        <div className='relative z-0 h-full min-w-0 min-h-0 bg-tileBackground'>
            <Tabs
                tabs={tabs}
                tabsID='datastreamPreviewTabs'
                tabListClassName='w-auto'
                selectedIndex={activePreviewTab}
                onSelect={(index) => setActivePreviewTab(index)}
                additionalControl={
                    <div className='flex px-4 my-auto ml-auto space-x-3'>
                        {isDataTab && (
                            <UnshapedDataOption
                                isShowingUnshapedData={isShowingUnshapedData}
                                setIsShowingUnshapedData={setIsShowingUnshapedData}
                            />
                        )}
                        {/* Show errors for all data sets if no specific data set is selected */}
                        <TileWarnings config={datasetConfig ?? tileConfig} />
                    </div>
                }
            />
        </div>
    );
};
