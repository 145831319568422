import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import Modal, { ModalButtons, ifNotOutside } from 'components/Modal';
import { TruncatedText } from 'components/TruncatedText';
import Button from 'components/button/Button';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import Tooltip from 'components/tooltip/Tooltip';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Workspace } from 'services/WorkspaceService';
import { PluginProjectedRow } from './Plugins';

interface PluginsWorkspacesModalProps {
    plugin: PluginProjectedRow;
    associatedWorkspaces: Workspace[];
    close: () => void;
}

const PluginsWorkspacesApplicationTable = ApplicationTable<Workspace, string>();

const PluginsWorkspacesModal: FC<PluginsWorkspacesModalProps> = ({ plugin, associatedWorkspaces, close }) => {
    const { canRead, canWrite, isLoading } = useWorkspacePermissions();

    if (isLoading) {
        return null;
    }

    const columns: ColumnDef<Workspace>[] = [
        {
            header: 'Workspace',
            cell: ({ row }) => {
                const canAccess = canRead(row.original);
                return (
                    <TruncatedText
                        title={canAccess ? `Go to workspace: ${row.original.displayName}` : row.original.displayName}
                    >
                        {canAccess ? (
                            <Button variant='link'>
                                <Link to={`/workspace/${row.original.id}`}>{row.original.displayName}</Link>
                            </Button>
                        ) : (
                            <div className='flex justify-start space-x-2 items-center w-full'>
                                <p className='text-textSecondary'>{row.original.displayName}</p>

                                <Tooltip
                                    title={'You do not have permission to access this workspace'}
                                    className={'text-textSecondary cursor-pointer shrink-0 ml-2'}
                                >
                                    <FontAwesomeIcon className='text-base' icon={faCircleQuestion} />
                                </Tooltip>
                            </div>
                        )}
                    </TruncatedText>
                );
            },
            accessorKey: 'displayName',
            size: 400
        }
    ];

    return (
        <Modal
            title={`Workspaces using ${plugin.displayName}`}
            close={ifNotOutside(close)}
            fullWidth
            maxWidth='max-w-3xl'
            maxHeight='h-fit'
        >
            <div className='flex flex-col h-full min-h-0 px-6 pt-5'>
                <PluginsWorkspacesApplicationTable
                    config={{
                        noDataMessage: 'There are no workspaces using this data source.'
                    }}
                    data={Array.from(associatedWorkspaces)}
                    columns={columns}
                />
            </div>
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={() => close()}>
                    Close
                </Button>
            </ModalButtons>
        </Modal>
    );
};

export default PluginsWorkspacesModal;
