import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { CloseHandler, ModalButtons } from 'components/Modal';
import type { KPIType } from 'dynamo-wrapper';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { DeleteType, KPI_TYPES } from 'services/KPIService';

interface KPITypeDeleteModalProps {
    type: KPIType;
    close: CloseHandler;
}
function KPITypeDeleteModal({ type, close }: KPITypeDeleteModalProps) {
    const [isDeleting, setDeleting] = useState(false);

    const queryClient = useQueryClient();

    const handleDelete = async () => {
        setDeleting(true);
        await DeleteType(type.id);

        setDeleting(false);
        queryClient.invalidateQueries(KPI_TYPES);
        close('submit');
    };

    const description = 'Are you sure you want to permanently delete this KPI type?';

    return (
        <Modal
            title={`Delete KPI Type: ${type.displayName}`}
            description={description}
            close={close}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <ModalButtons>
                <Button variant='tertiary' onClick={close}>
                    Cancel
                </Button>
                <Button
                    variant='destructive'
                    type='submit'
                    onClick={handleDelete}
                    disabled={isDeleting}
                    data-testid='deleteKPITypeConfirm'
                >
                    {isDeleting ? <LoadingSpinner size={18} /> : 'Delete'}
                </Button>
            </ModalButtons>
        </Modal>
    );
}

export default KPITypeDeleteModal;
