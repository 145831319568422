import { Button, TooltipButton } from '@/components/Button';
import { Checkbox } from '@/components/forms/Checkbox';
import { EyeCheckbox } from '@/components/forms/EyeCheckbox';
import { cn } from '@/lib/cn';
import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { ScrollArea } from 'components/scrollArea';
import { clamp } from 'lodash';
import { useEffect, useRef, useState } from 'react';

function Root({ children, onOpenChange, ...props }: DropdownMenu.DropdownMenuProps) {
    return (
        <DropdownMenu.Root onOpenChange={onOpenChange} {...props}>
            {children}
        </DropdownMenu.Root>
    );
}

export interface ButtonProps {
    children?: React.ReactNode;
    icon?: React.ReactNode;
    showChevron?: boolean;
    chevronDefaultClass?: string;
    chevronActiveClass?: string;
    useFilterStyle?: boolean;
    className?: string;
    active?: boolean;
    dropdownOpen?: boolean;
    title?: string;
    disabled?: boolean;
    [key: `data-${string}`]: string;
}
function DropdownButton({
    children,
    icon,
    showChevron,
    chevronDefaultClass = 'text-textSecondary',
    chevronActiveClass = 'text-textPrimary',
    useFilterStyle,
    className,
    active,
    dropdownOpen,
    title,
    disabled,
    ...dataProps
}: ButtonProps) {
    const Comp = title ? TooltipButton : Button;

    return (
        <DropdownMenu.Trigger
            disabled={disabled}
            className={
                useFilterStyle
                    ? cn(
                          'outline-none group hover:bg-secondaryButtonBackgroundHover hover:ring-secondaryButtonOutlineHover',
                          active && 'bg-filterActive ring-outlineSecondary hover:bg-tagBackground',
                          dropdownOpen && 'bg-componentBackgroundPrimary ring-outlineSecondary',
                          className
                      )
                    : cn('outline-none group', className)
            }
            asChild
            {...dataProps}
        >
            <Comp icon={icon as any} variant='secondary' title={title}>
                {children as any}
                {showChevron && (
                    <FontAwesomeIcon
                        className={cn(
                            `ml-3 ${chevronDefaultClass} group-hover:${chevronActiveClass} self-center`,
                            dropdownOpen && chevronActiveClass,
                            disabled && 'text-textDisabled'
                        )}
                        icon={dropdownOpen ? faChevronUp : faChevronDown}
                    />
                )}
            </Comp>
        </DropdownMenu.Trigger>
    );
}

export interface MenuProps {
    children: React.ReactNode;
    className?: string;
    sideOffset?: number;
    alignOffset?: number;
    zIndex?: number;
    container?: HTMLElement | null;
    align?: 'start' | 'end' | 'center';
    theme?: 'dark' | 'light';
    portal?: boolean;
    noScroll?: boolean;
}
function Menu({
    children,
    className,
    sideOffset,
    alignOffset,
    container,
    theme,
    zIndex = 100,
    align = 'start',
    portal = true,
    noScroll = false
}: MenuProps) {
    const content = (
        <DropdownMenu.Content
            onCloseAutoFocus={(e) => {
                e.preventDefault();
            }}
            align={align}
            className={cn(
                'mb-0 border shadow-none rounded-b-input bg-componentBackgroundPrimary text-textPrimary border-outlinePrimary p-xxxs overflow-auto max-h-[--radix-dropdown-menu-content-available-height]',
                className
            )}
            sideOffset={sideOffset ?? 5}
            alignOffset={alignOffset ?? 0}
            style={{ zIndex }}
            {...(theme && { 'data-theme': theme })}
        >
            {noScroll ? children : <ScrollArea>{children}</ScrollArea>}
        </DropdownMenu.Content>
    );

    if (portal) {
        return <DropdownMenu.Portal container={container ?? document.body}>{content}</DropdownMenu.Portal>;
    }

    return content;
}

export interface ItemProps {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    onSelect?: (event: Event) => void;
}

function Item({ children, className, disabled, onSelect }: ItemProps) {
    return (
        <DropdownMenu.Item
            onSelect={onSelect}
            disabled={disabled}
            className={cn(
                'cursor-pointer leading-input focus:bg-dividerTertiary py-autocomplete focus:ring-0 focus:outline-none px-xs',
                disabled && 'hover:bg-transparent text-textDisabled cursor-auto',
                className
            )}
        >
            {children}
        </DropdownMenu.Item>
    );
}

export interface CheckItemProps {
    children: React.ReactNode;
    className?: string;
    checked?: boolean;
    onSelect?: (event: Event) => void;
    onCheckedChange?: (checked: boolean) => void;
    disabled?: boolean;
    testId?: string;
}

function CheckItem({ children, className, onCheckedChange, checked, onSelect, disabled, testId }: CheckItemProps) {
    return (
        <DropdownMenu.CheckboxItem
            className={clsx(
                className,
                'relative cursor-pointer leading-input focus:bg-dividerTertiary py-autocomplete focus:ring-0 focus:outline-none px-xs',
                checked && 'pr-10 bg-dividerPrimary',
                disabled && 'text-textDisabled pointer-events-none'
            )}
            onCheckedChange={onCheckedChange}
            checked={checked}
            onSelect={onSelect}
            disabled={disabled}
            data-testid={testId}
        >
            {children}
            <DropdownMenu.ItemIndicator className='absolute -translate-y-1/2 text-textPrimary right-4 top-1/2'>
                <FontAwesomeIcon icon={faCheck} />
            </DropdownMenu.ItemIndicator>
        </DropdownMenu.CheckboxItem>
    );
}

export interface CheckboxItemProps {
    children: React.ReactNode;
    className?: string;
    checked?: boolean;
    disabled?: boolean;
    onCheckedChange?: (checked: boolean) => void;
    testId?: string;
}

function CheckboxItem({ children, className, onCheckedChange, checked, disabled, testId }: CheckboxItemProps) {
    return (
        <DropdownMenu.CheckboxItem
            onSelect={(event) => event.preventDefault()}
            className={cn(
                'cursor-pointer leading-input focus:bg-dividerTertiary focus:ring-0 focus:outline-none py-autocomplete px-xs pr-lg',
                { 'cursor-auto opacity-50': disabled },
                className
            )}
            onCheckedChange={onCheckedChange}
            checked={checked}
            disabled={disabled}
            data-testid={testId}
        >
            <span className='flex items-center'>
                <Checkbox checked={checked} className='mr-xs' />
                {children}
            </span>
        </DropdownMenu.CheckboxItem>
    );
}

export interface EyeCheckboxItemProps {
    children: React.ReactNode;
    className?: string;
    checked?: boolean;
    onCheckedChange?: (checked: boolean) => void;
    disabled?: boolean;
}

function EyeCheckboxItem({ children, className, onCheckedChange, checked, disabled }: EyeCheckboxItemProps) {
    return (
        <DropdownMenu.CheckboxItem
            onSelect={(event) => !disabled && event.preventDefault()}
            disabled={disabled}
            className={clsx(
                className,
                'cursor-pointer leading-input focus:bg-dividerTertiary focus:ring-0 focus:outline-none py-autocomplete px-xs'
            )}
            onCheckedChange={!disabled ? onCheckedChange : undefined}
            checked={checked}
        >
            <label
                className={cn(
                    'flex items-center  justify-between space-x-2 pointer-events-none',
                    disabled && 'text-textDisabled'
                )}
            >
                {children}
                <EyeCheckbox disabled={disabled} checked={disabled ? false : checked} className='ml-md' />
            </label>
        </DropdownMenu.CheckboxItem>
    );
}

export interface NumberItemProps {
    children: React.ReactNode;
    className?: string;
    value?: number;
    minimum?: number;
    maximum?: number;
    onValueChange?: (value: number) => void;
}

function NumberItem({ children, className, onValueChange, value, minimum, maximum }: NumberItemProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [internalValue, setInternalValue] = useState(value);

    const stepUp = () => {
        inputRef?.current?.stepUp();

        const changeEvent = new Event('change', { bubbles: true });
        inputRef?.current?.dispatchEvent(changeEvent);
    };

    const stepDown = () => {
        inputRef?.current?.stepDown();

        const changeEvent = new Event('change', { bubbles: true });
        inputRef?.current?.dispatchEvent(changeEvent);
    };

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    return (
        <DropdownMenu.Group
            className={clsx(
                className,
                'cursor-pointer leading-input focus:bg-dividerTertiary focus:ring-0 focus:outline-none py-autocomplete px-xs'
            )}
        >
            <label className='flex items-center justify-between space-x-2 select-none'>
                {children}
                <div className='flex bg-transparent ring-1 ring-dividerPrimary rounded-input'>
                    <input
                        ref={inputRef}
                        className='w-8 px-2 py-1 bg-transparent border-0 outline-none ring-0 focus:ring-0 focus:border-0 focus:outline-none remove-arrows'
                        type='number'
                        value={internalValue}
                        onChange={(e) => {
                            const v = e.target.value
                                ? clamp(Number(e.target.value), minimum ?? -Infinity, maximum ?? Infinity)
                                : undefined;
                            setInternalValue(v);
                            v && onValueChange?.(v);
                        }}
                        min={minimum}
                        max={maximum}
                        inputMode='numeric'
                    />
                    <div className='flex flex-col'>
                        <div
                            className='flex items-end justify-center flex-1 px-1 hover:bg-secondaryButtonOutline'
                            onClick={stepUp}
                        >
                            <FontAwesomeIcon size='xs' icon={faChevronUp} />
                        </div>
                        <div
                            className='flex items-start justify-center flex-1 px-1 hover:bg-secondaryButtonOutline'
                            onClick={stepDown}
                        >
                            <FontAwesomeIcon size='xs' icon={faChevronDown} />
                        </div>
                    </div>
                </div>
            </label>
        </DropdownMenu.Group>
    );
}

export interface SecondaryItemProps {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    onSelect?: (event: Event) => void;
}

function SecondaryItem({ children, className, disabled, onSelect }: SecondaryItemProps) {
    return (
        <DropdownMenu.DropdownMenuItem
            disabled={disabled}
            className={clsx(
                'leading-input py-autocomplete px-xs pr-lg focus:bg-dividerTertiary focus:ring-0 focus:outline-none',
                disabled ? 'text-textDisabled hover:bg-transparent' : 'cursor-pointer',
                className
            )}
            onSelect={onSelect}
        >
            {children}
        </DropdownMenu.DropdownMenuItem>
    );
}

export interface GroupProps {
    children: React.ReactNode;
    className?: string;
}

function Group({ children, className }: GroupProps) {
    return <DropdownMenu.Group className={className}>{children}</DropdownMenu.Group>;
}

export interface SeparatorProps {
    className?: string;
}

function Separator({ className }: SeparatorProps) {
    return (
        <DropdownMenu.Separator asChild>
            <hr className={cn('border-dividerPrimary my-xxxs', className)} />
        </DropdownMenu.Separator>
    );
}

export default Object.assign(Root, {
    Button: DropdownButton,
    Menu,
    Item,
    Group,
    CheckboxItem,
    EyeCheckboxItem,
    NumberItem,
    Separator,
    CheckItem,
    SecondaryItem
});
