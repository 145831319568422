import { ResizablePanelResizeHandle } from 'components/ResizablePanelResizeHandle';
import { GlobalDataStreamContext } from 'dashboard-engine/dataStreams/GlobalDataStreamContext';
import { ComponentRef, useRef } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import {
    editorStepsHeightPercentage,
    vizConfigPanelWidthPixels
} from 'ui/editor/dataStream/TileEditor/DataStreamTileEditor';
import { DataStreamTileEditorPreview } from 'ui/editor/dataStream/TileEditor/DataStreamTileEditorPreview';
import { DataStreamTileEditorVisualizationPanel } from 'ui/editor/dataStream/TileEditor/DataStreamTileEditorVisualizationPanel';
import { useDatasets } from 'ui/editor/dataStream/TileEditor/hooks/useDatasets';
import { DataStreamTileEditorSteps } from 'ui/editor/dataStream/TileEditor/steps/DataStreamTileEditorSteps';
import DatasetContext from 'ui/editor/dataStream/contexts/DatasetContext';
import { DraftSQLContextProvider } from 'ui/editor/dataStream/contexts/DraftSQLContext';
import { useTileEditorContext } from 'ui/editor/dataStream/contexts/TileEditorContext';
import { DatasetEditor } from 'ui/editor/dataStream/datasets/DatasetEditor';

interface ExploreTileEditorWrapperProps {
    workspace?: string;
    isGlobal?: boolean;
}

export const ExploreTileEditorWrapper: React.FC<ExploreTileEditorWrapperProps> = ({ workspace, isGlobal }) => {
    const key = `explore-${isGlobal ? 'global' : 'workspace'}`;

    return isGlobal || workspace ? (
        <GlobalDataStreamContext workspace={workspace}>
            <ExploreTileEditor key={key} />
        </GlobalDataStreamContext>
    ) : (
        <ExploreTileEditor key={key} />
    );
};

/**
 * A stripped back version of the data stream tile editor used on the Analyze/Explore page
 * e.g. no monitoring/KPI tab
 */
export const ExploreTileEditor: React.FC<ExploreTileEditorWrapperProps> = () => {
    const { isDatasetMode, savedTileConfig } = useTileEditorContext();
    const datasetState = useDatasets();

    const horizontalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);
    const verticalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);

    const handleResetHorizontalPanelGroupLayout = () => {
        if (!horizontalPanelGroupRef.current) {
            return;
        }
        const totalSize = horizontalPanelGroupRef.current.getLayout().reduce((acc, cur) => acc + cur.sizePixels, 0);
        horizontalPanelGroupRef.current.setLayout([
            { sizePixels: totalSize - vizConfigPanelWidthPixels },
            { sizePixels: vizConfigPanelWidthPixels }
        ]);
    };

    const handleResetVerticalPanelGroupLayout = () => {
        verticalPanelGroupRef.current?.setLayout([
            { sizePercentage: editorStepsHeightPercentage },
            { sizePercentage: 100 - editorStepsHeightPercentage }
        ]);
    };

    return (
        <div className='w-full h-full pb-6 overflow-hidden'>
            <DatasetContext.Provider value={datasetState}>
                <PanelGroup ref={horizontalPanelGroupRef} direction='horizontal'>
                    <Panel>
                        <DraftSQLContextProvider initialQuery={savedTileConfig.dataStream?.dataSourceConfig?.sql ?? ''}>
                            <PanelGroup ref={verticalPanelGroupRef} direction='vertical'>
                                <Panel
                                    collapsible={true}
                                    minSizePixels={100}
                                    defaultSizePercentage={editorStepsHeightPercentage}
                                    className='bg-tileBackground text-textSecondary'
                                >
                                    {isDatasetMode ? <DatasetEditor /> : <DataStreamTileEditorSteps />}
                                </Panel>
                                <ResizablePanelResizeHandle
                                    direction='vertical'
                                    onResetLayout={handleResetVerticalPanelGroupLayout}
                                />
                                <Panel collapsible={true} minSizePixels={100}>
                                    <DataStreamTileEditorPreview />
                                </Panel>
                            </PanelGroup>
                        </DraftSQLContextProvider>
                    </Panel>
                    <ResizablePanelResizeHandle
                        direction='horizontal'
                        onResetLayout={handleResetHorizontalPanelGroupLayout}
                    />
                    <Panel
                        collapsible={true}
                        minSizePixels={200}
                        maxSizePercentage={50}
                        defaultSizePixels={vizConfigPanelWidthPixels}
                    >
                        <div className='flex flex-col h-full min-h-0 bg-tileBackground'>
                            <DataStreamTileEditorVisualizationPanel />
                        </div>
                    </Panel>
                </PanelGroup>
            </DatasetContext.Provider>
        </div>
    );
};
