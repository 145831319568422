import type { AcePermission } from 'dynamo-wrapper';
import { isOpenAccess } from 'lib/openAccessUtil';
import { fiveMinutes } from 'queries/constants';
import { useTenant } from 'queries/hooks/useTenant';
import { useQuery } from 'react-query';
import { AccessControlQueryKeys, GetEntityTypePermissions, IsTenantAdmin } from 'services/AccessControlService';

export interface Workspace {
    id: string;
}

const editPermissions: AcePermission[] = ['RW', 'AD'];

export const useWorkspacePermissions = () => {
    const { data: workspacePermissions, isLoading: isLoadingPermissions } = useQuery(
        AccessControlQueryKeys.EntityTypePermissions('space'),
        () => GetEntityTypePermissions('space'),
        {
            enabled: !isOpenAccess(),
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes * 2
        }
    );

    const { data: tenant, isLoading: isTenantLoading } = useTenant({
        // We're just using this to check if caller is tenant admin - that's not going to change.
        // (If it does, we'll realistically need to reload the app anyway)
        staleTime: Number.POSITIVE_INFINITY,
        cacheTime: Number.POSITIVE_INFINITY
    });

    /**
     * Helper function to check whether the user can write to at least one workspace that isn't one
     * of the optionally passed workspaceIds
     * @param workspaceIdExclusions An array of workspaceIds to exclude
     * @returns True if the user can write to at least one workspace
     */
    const canWriteToAtLeastOneWorkspace = (workspaceIdExclusions?: string[]) => {
        return workspacePermissions?.some(({ objectId, permissions }) => {
            if (workspaceIdExclusions?.includes(objectId)) {
                return false;
            }

            return editPermissions.some((perm) => permissions.includes(perm));
        });
    };

    const canWrite = (workspace: Workspace) => {
        if (workspacePermissions) {
            const permissions = workspacePermissions.find((perm) => perm.objectId === workspace.id)?.permissions;
            return permissions && editPermissions.some((perm) => permissions.includes(perm));
        }
        return false;
    };

    const canEditSettings = (workspace: Workspace) => {
        if (IsTenantAdmin(tenant)) {
            return true;
        }
        if (workspacePermissions) {
            const permissions = workspacePermissions.find((perm) => perm.objectId === workspace.id)?.permissions;
            return permissions && editPermissions.some((perm) => permissions.includes(perm));
        }
        return false;
    };

    const canDelete = (workspace: Workspace) => {
        if (IsTenantAdmin(tenant)) {
            return true;
        }
        if (workspacePermissions) {
            const permissions = workspacePermissions.find((perm) => perm.objectId === workspace.id)?.permissions;
            const isAdmin = permissions && permissions.includes('AD');
            return isAdmin;
        }
        return false;
    };

    const canRead = (workspace: Workspace) => {
        const readPermissions: AcePermission[] = ['RO', 'RW', 'AD'];

        if (workspacePermissions) {
            const permissions = workspacePermissions.find((perm) => perm.objectId === workspace.id)?.permissions;

            return (permissions && permissions.some((perm) => readPermissions.includes(perm))) ?? false;
        }

        return false;
    };

    return {
        canWriteToAtLeastOneWorkspace,
        canWrite,
        canEditSettings,
        canDelete,
        canRead,
        isLoading: isTenantLoading || isLoadingPermissions
    };
};
