import { DataStreamBaseTileConfig, DataStreamSQLConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { getIsDataStreamConfigured } from 'dashboard-engine/util/getIsDataStreamConfigured';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { DATA_STREAM_DEFINITION } from 'services/DataStreamDefinitionService';
import { TabOption } from 'ui/Tabs';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { DatasetTab } from '../../datasets/DatasetTab';
import { AnalyticsQueryEditorSteps } from '../analytics/AnalyticsQueryEditorSteps';
import { DataStreamTileEditorSteps } from '../steps/DataStreamTileEditorSteps';

type ConfigSetter = ((newConfig: DataStreamBaseTileConfig) => DataStreamBaseTileConfig) | DataStreamBaseTileConfig;
type Datasets = DataStreamSQLConfig['dataSourceConfig']['tables'];

export const defaultSQL = 'SELECT * FROM dataset1';

export const useDatasets = () => {
    const queryClient = useQueryClient();
    const { variables } = useDashboardContext();
    const { isDatasetMode, tileConfig, savedTileConfig, setTileConfig } = useTileEditorContext();

    const datasets = (tileConfig.dataStream?.dataSourceConfig?.tables || []) as Datasets;
    const sql = tileConfig.dataStream?.dataSourceConfig?.sql;

    const isConfigured = datasets.some((dataset) => {
        const dataStreamDefinition = queryClient.getQueryData([DATA_STREAM_DEFINITION, dataset.config?.dataStream?.id]);
        return getIsDataStreamConfigured(dataStreamDefinition, dataset.config as DataStreamBaseTileConfig);
    });

    const { limit } = useCheckScopeIsOobAndHasLimit(datasets?.[0]?.config as DataStreamBaseTileConfig);

    /**
     * If we're starting the editor in SQL mode or the user is switching with a complete data stream configuration
     * we take the user to the SQL tab, else, we take the user to the dataset editor to finish configuration.
     * */
    const [activeDataset, setActiveDataset] = useState(
        (savedTileConfig?.dataStream?.id === 'datastream-sql' || isConfigured) && limit === false ? datasets.length : 0
    );
    /**
     * By default show the Visualization tab if opening editor in SQL mode or default mode.
     * If user enables SQL Analytics afterwards, show Output tab if the tile was configured with a datastream.
     * Otherwise shows the dataset1 tab, if no datastream was configured on enabling SQL analytics.
     */
    const [activePreviewTab, setActivePreviewTab] = useState(
        isDatasetMode && savedTileConfig?.dataStream?.id !== 'datastream-sql'
            ? isConfigured
                ? datasets.length + 1
                : 1
            : 0
    );

    const activeDatasetTableName = datasets?.[activeDataset]?.tableName || `Dataset ${activeDataset + 1}`;
    const activeDatasetConfig = (datasets?.[activeDataset]?.config || baseDataStreamConfig) as DataStreamBaseTileConfig;

    /**
     * Get variables for root tile config if any dataset uses them.
     *
     * The SQL handler needs them present in the root to be able to resolve them.
     */
    const getTileConfigVariables = (newDatasets: DataStreamSQLConfig['dataSourceConfig']['tables']) => {
        const hasVariables = newDatasets.some((dataset) => dataset.config?.variables?.length);
        return hasVariables ? variables?.map((v) => v.id) : undefined;
    };

    const updateDatasets = (
        newDatasets: DataStreamSQLConfig['dataSourceConfig']['tables'],
        newActiveDataset: number
    ) => {
        setTileConfig((currentTileConfig) => ({
            ...currentTileConfig,
            variables: getTileConfigVariables(newDatasets),
            dataStream: {
                ...currentTileConfig.dataStream,
                dataSourceConfig: {
                    ...currentTileConfig.dataStream?.dataSourceConfig,
                    tables: newDatasets
                }
            }
        }));

        setActiveDataset(newActiveDataset);
    };

    const addDataset = () => {
        const newDatasets = [...datasets];

        // Generate dataset name
        let datasetNumber = 1;
        const existingTableNames = datasets.map(({ tableName }: { tableName: string }) => tableName);

        while (existingTableNames.includes(`dataset${datasetNumber}`)) {
            datasetNumber++;
        }

        newDatasets.push({
            tableName: `dataset${datasetNumber}`,
            config: {
                // If variable added to dashboard, add to dataset by default
                variables: variables?.map((v) => v.id)
            }
        });

        updateDatasets(newDatasets, newDatasets.length - 1);

        // Go to the new datasets data preview
        setActivePreviewTab(newDatasets.length);
    };

    const cloneDataset = (index: number) => {
        const existing = datasets[index];

        // Generate a new name
        const existingTableNames = datasets.map(({ tableName }: { tableName: string }) => tableName);
        const name = `${existing.tableName}Copy`;
        let count = 1;
        let newName = name;

        while (existingTableNames.includes(newName)) {
            newName = name + count;
            count++;
        }

        const newDatasets = [...datasets, { ...existing, tableName: newName }];
        updateDatasets(newDatasets, newDatasets.length - 1);
        setActivePreviewTab(newDatasets.length);
    };

    const removeDataset = (index: number) => {
        const newDatasets = [...datasets];
        newDatasets.splice(index, 1);

        const newActiveTab = index >= activeDataset ? activeDataset : activeDataset - 1;

        updateDatasets(newDatasets, newActiveTab);
        setActivePreviewTab(newActiveTab + 1);
    };

    const renameDataset = (targetDataset: number, newDataName: string) => {
        const cleanedDatasetName = newDataName.trim();

        setTileConfig((currentTileConfig) => {
            const newDatasets = [...(datasets || [])];
            const oldDatasetName = datasets?.[targetDataset]?.tableName;
            const newSQL = sql === defaultSQL ? sql.replace(oldDatasetName, cleanedDatasetName) : sql;

            if (targetDataset !== undefined) {
                newDatasets[targetDataset] = {
                    tableName: cleanedDatasetName,
                    config: newDatasets[targetDataset].config
                };
            }

            return {
                ...currentTileConfig,
                dataStream: {
                    ...currentTileConfig.dataStream,
                    dataSourceConfig: {
                        ...currentTileConfig.dataStream?.dataSourceConfig,
                        tables: newDatasets,
                        sql: newSQL
                    }
                }
            };
        });
    };

    const setDatasetConfig = (newConfig: ConfigSetter) => {
        setTileConfig((currentTileConfig) => {
            const newDatasets = [...(datasets || [])];

            // If there is an active dataset we want to replace the config else we want to push the new one
            if (activeDataset !== undefined) {
                newDatasets[activeDataset] = {
                    tableName: activeDatasetTableName,
                    // If we're using a callback we want to resolve it with the current activeDatasetConfig, else
                    // we just insert the new dataset config
                    config: typeof newConfig === 'function' ? newConfig(activeDatasetConfig) : newConfig
                };
            }
            return {
                ...currentTileConfig,
                variables: getTileConfigVariables(newDatasets),
                dataStream: {
                    ...currentTileConfig.dataStream,
                    dataSourceConfig: {
                        ...currentTileConfig.dataStream?.dataSourceConfig,
                        tables: newDatasets
                    }
                }
            };
        });
    };

    // Generate the tabs for the navigating the data stream datasets
    const datasetTabs = [
        ...(datasets?.reduce((tabs, dataset, index) => {
            if (dataset) {
                const datasetName = dataset.tableName || `dataset${index + 1}`;

                tabs.push({
                    key: datasetName,
                    className: 'p-0',
                    label: (
                        <DatasetTab
                            key={datasetName}
                            datasetName={datasetName}
                            onSave={(newDatasetName) => renameDataset(index, newDatasetName)}
                            isActive={activePreviewTab - 1 === index}
                            existingNames={datasets.map((d) => d.tableName)}
                            onClone={() => cloneDataset(index)}
                            onDelete={() => removeDataset(index)}
                        />
                    ),
                    component: <DataStreamTileEditorSteps key={datasetName} />
                });
            }

            return tabs;
        }, [] as TabOption[]) || []),
        {
            label: 'SQL',
            component: <AnalyticsQueryEditorSteps />
        }
    ];

    const config = !isDatasetMode ? tileConfig : activeDatasetConfig;
    const setConfig = !isDatasetMode ? setTileConfig : setDatasetConfig;

    return {
        datasets,
        datasetTabs,
        config,
        activeDataset,
        activePreviewTab,
        addDataset,
        setConfig,
        setActiveDataset,
        renameDataset,
        setActivePreviewTab
    };
};
