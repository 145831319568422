import { Config } from 'tailwindcss';
import defaults from 'tailwindcss/defaultTheme';

const getPlugin = typeof require === 'undefined' ? () => undefined : require;

export const squaredUpTailwindConfig = {
    content: ['./src/**/*.{ts,js,jsx,tsx}'],
    theme: {
        fontFamily: {
            ...defaults.fontFamily,
            sans: 'Montserrat, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            inter: 'Inter, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
        },
        extend: {
            // TODO: switch padding/margin to spacing once we're enforcing our config
            padding: {
                xxl: '48px',
                xl: '32px',
                lg: '24px',
                md: '20px',
                sm: '16px',
                xs: '12px',
                xxs: '8px',
                xxxs: '4px',
                autocomplete: '7px',
                autocompleteMulti: '6px',
                smallButton: '7px',
                input: '11px',
                inputWithBorder: '10px',
                toggleWrapper: '6px',
                radioWrapper: '9px'
            },
            margin: {
                xxl: '48px',
                xl: '32px',
                lg: '24px',
                md: '20px',
                sm: '16px',
                xs: '12px',
                xxs: '8px',
                xxxs: '4px',
                input: '11px'
            },
            gap: {
                xxl: '48px',
                xl: '32px',
                lg: '24px',
                md: '20px',
                sm: '16px',
                xs: '12px',
                xxs: '8px',
                xxxs: '4px',
                input: '11px'
            },
            borderRadius: {
                checkbox: '2px',
                input: '4px',
                tileRadius: '4px'
            },
            borderWidth: {
                radioBorder: '5px',
                disabledRadioBorder: '1px'
            },
            containers: {
                '8xl': '90rem',
                '9xl': '100rem'
            },
            width: {
                radioBullet: '6px',
                disabledRadio: '14px',
                checkbox: '16px',
                toggleThumb: '22px',
                toggleTrack: '38px'
            },
            height: {
                radioBullet: '6px',
                disabledRadio: '14px',
                checkbox: '16px',
                toggleThumb: '22px',
                toggleTrack: '22px',
                button: '2.857rem' // 2.857rem = ~40px
            },
            minHeight: {
                // hotfix for Safari table cells not aligning correctly due to sub-pixel line-height rounding
                // added 1px to account for table border bottom.
                tableCell: 'calc(2.25rem + 1px)'
            },
            translate: {
                toggleThumb: '16px'
            },
            lineHeight: {
                input: '18px'
            },
            letterSpacing: {
                body: '-0.005em'
            },
            colors: {
                // -------------
                // Design system
                // -------------
                textPrimary: 'var(--textPrimary)',
                textSecondary: 'var(--textSecondary)',
                textDisabled: 'var(--textDisabled)',
                textIncomplete: 'var(--textIncomplete)',
                textLink: 'var(--textLink)',
                textLinkDisabled: 'var(--textLinkDisabled)',
                textDestructive: 'var(--textDestructive)',
                textDestructiveDisabled: 'var(--textDestructiveDisabled)',
                textTable: 'var(--textTable)',
                textSearchSecondary: 'var(--textSearchSecondary)',

                /* Background colors */
                backgroundPrimary: 'var(--backgroundPrimary)',
                backgroundSecondary: 'var(--backgroundSecondary)',
                tileBackground: 'var(--tileBackground)',
                modalBackground: 'var(--modalBackground)',

                /* Component colors */
                componentBackgroundPrimary: 'var(--componentBackgroundPrimary)',
                componentBackgroundSecondary: 'var(--componentBackgroundSecondary)',
                componentActive: 'var(--componentActive)',
                componentDisabled: 'var(--componentDisabled)',
                componentToggle: 'var(--componentToggle)',
                componentTileBlock: 'var(--componentTileBlock)',
                componentTileBlockHover: 'var(--componentTileBlockHover)',
                workspaceIcon: 'var(--workspaceIcon)',
                workspaceIconBackground: 'var(--workspaceIconBackground)',
                tileEditorComponent: 'var(--tileEditorComponent)',
                tileEditorComponentActive: 'var(--tileEditorComponentActive)',
                tileEditorPresetCategoryText: 'var(--tileEditorPresetCategoryText)',
                tileEditorPresetCategoryTextActive: 'var(--tileEditorPresetCategoryTextActive)',

                /* Outline colors */
                outlinePrimary: 'var(--outlinePrimary)',
                outlineSecondary: 'var(--outlineSecondary)',
                outlineSecondaryHover: 'var(--outlineSecondaryHover)',
                outlineTertiary: 'var(--outlineTertiary)',
                tileOutline: 'var(--tileOutline)',
                modalOutline: 'var(--modalOutline)',
                workspaceIconOutline: 'var(--workspaceIconOutline)',
                helpOutline: 'var(--helpOutline)',

                /* Divider colors */
                dividerPrimary: 'var(--dividerPrimary)',
                dividerSecondary: 'var(--dividerSecondary)',
                dividerTertiary: 'var(--dividerTertiary)',

                /* Button colors */
                primaryButtonBackground: 'var(--primaryButtonBackground)',
                primaryButtonBackgroundHover: 'var(--primaryButtonBackgroundHover)',
                primaryButtonBackgroundDisabled: 'var(--primaryButtonBackgroundDisabled)',
                primaryButtonDivider: 'var(--primaryButtonDivider)',
                primaryButtonDividerDisabled: 'var(--primaryButtonDividerDisabled)',
                destructiveButtonBackground: 'var(--destructiveButtonBackground)',
                destructiveButtonBackgroundHover: 'var(--destructiveButtonBackgroundHover)',
                destructiveButtonBackgroundDisabled: 'var(--destructiveButtonBackgroundDisabled)',
                destructiveButtonDivider: 'var(--destructiveButtonDivider)',
                destructiveButtonDividerDisabled: 'var(--destructiveButtonDividerDisabled)',
                secondaryButtonBackground: 'var(--secondaryButtonBackground)',
                secondaryButtonBackgroundHover: 'var(--secondaryButtonBackgroundHover)',
                secondaryButtonBackgroundDisabled: 'var(--secondaryButtonBackgroundDisabled)',
                secondaryButtonDivider: 'var(--secondaryButtonDivider)',
                secondaryButtonDividerDisabled: 'var(--secondaryButtonDividerDisabled)',
                secondaryButtonOutline: 'var(--secondaryButtonOutline)',
                secondaryButtonOutlineHover: 'var(--secondaryButtonOutlineHover)',
                secondaryButtonOutlineDisabled: 'var(--secondaryButtonOutlineDisabled)',
                tertiaryButton: 'var(--tertiaryButton)',
                tertiaryButtonHover: 'var(--tertiaryButtonHover)',
                tertiaryButtonDisabled: 'var(--tertiaryButtonDisabled)',
                primaryButtonText: 'var(--primaryButtonText)',
                primaryButtonTextDisabled: 'var(--primaryButtonTextDisabled)',
                secondaryButtonText: 'var(--secondaryButtonText)',
                secondaryButtonTextDisabled: 'var(--secondaryButtonTextDisabled)',
                upgradeButtonBackgroundPrimary: 'var(--upgradeButtonBackgroundPrimary)',
                upgradeButtonBackgroundHover: 'var(--upgradeButtonBackgroundHover)',
                upgradeButtonBackgroundDisabled: 'var(--upgradeButtonBackgroundDisabled)',
                upgradeButtonTextPrimary: 'var(--upgradeButtonTextPrimary)',
                upgradeButtonTextDisabled: 'var(--upgradeButtonTextDisabled)',

                /* Status colors */
                statusHealthyPrimary: 'var(--statusHealthyPrimary)',
                statusHealthySecondary: 'var(--statusHealthySecondary)',
                statusHealthyFaded: 'var(--statusHealthyFaded)',
                statusWarningPrimary: 'var(--statusWarningPrimary)',
                statusWarningSecondary: 'var(--statusWarningSecondary)',
                statusWarningFaded: 'var(--statusWarningFaded)',
                statusErrorPrimary: 'var(--statusErrorPrimary)',
                statusErrorSecondary: 'var(--statusErrorSecondary)',
                statusErrorFaded: 'var(--statusErrorFaded)',
                statusUnknownPrimary: 'var(--statusUnknownPrimary)',
                statusUnknownSecondary: 'var(--statusUnknownSecondary)',
                statusUnknownFaded: 'var(--statusUnknownFaded)',
                statusAction: 'var(--statusAction)',
                statusActionFaded: 'var(--statusActionFaded)',
                statusBlockFaded: 'var(--statusBlockFaded)',
                statusUnmonitoredPrimary: 'var(--statusUnmonitoredPrimary)',
                statusUnmonitoredSecondary: 'var(--statusUnmonitoredSecondary)',

                /* Card colors */
                cardContainer: 'var(--cardContainer)',
                cardBackground: 'var(--cardBackground)',
                cardOutline: 'var(--cardOutline)',
                cardIconBackground: 'var(--cardIconBackground)',
                cardTypeBackground: 'var(--cardTypeBackground)',
                cardTypeOutline: 'var(--cardTypeOutline)',

                /* Miscellaneous colors */
                filterActive: 'var(--filterActive)',
                tagBackground: 'var(--tagBackground)',
                modalFooter: 'var(--modalFooter)',
                tooltipBackground: 'var(--tooltipBackground)',
                toggleBody: 'var(--toggleBody)',
                toggleBodyHover: 'var(--toggleBodyHover)',
                tableHover: 'var(--tableHover)',
                tableEvenRow: 'var(--tableEvenRow)',
                upgrade: 'var(--upgrade)',
                navBarDropIndicator: 'var(--navBarDropIndicator)',
                navBarBlockedIndicator: 'var(--navBarBlockedIndicator)',
                iconAsk: 'var(--iconAsk)',
                iconDocs: 'var(--iconDocs)',
                iconContactSupport: 'var(--iconContactSupport)',
                iconWhatsNew: 'var(--iconWhatsNew)',
                textIcon: 'var(--textIcon)',

                /* Format Expressions Code Colours */
                formatExpressionLiteral: 'var(--formatExpressionLiteral) !important',
                formatExpressionTemplateDelimiter: 'var(--formatExpressionTemplateDelimiter) !important'
            },
            screens: {
                '2xl': '1720px',
                '3xl': '2020px'
            },
            animation: {
                enter: 'enter 200ms ease-out',
                leave: 'leave 150ms ease-in forwards',
                accordionDown: 'accordion-down 0.2s ease-out',
                accordionUp: 'accordion-up 0.2s ease-out',
                pingNotify: 'ping 1s cubic-bezier(0, 0, 0.2, 1) 3',
                ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
                pingSubtle: 'pingSubtle 1s cubic-bezier(0, 0, 0.2, 1) infinite'
            },
            keyframes: {
                enter: {
                    from: { transform: 'scale(0.9)', opacity: '0' },
                    to: { transform: 'scale(1)', opacity: '1' }
                },
                leave: {
                    from: { transform: 'scale(1)', opacity: '1' },
                    to: { transform: 'scale(0.9)', opacity: '0' }
                },
                accordionDown: {
                    from: { height: '0' },
                    to: { height: 'var(--radix-accordion-content-height)' }
                },
                accordionUp: {
                    from: { height: 'var(--radix-accordion-content-height)' },
                    to: { height: '0' }
                },
                pingSubtle: {
                    '75%': {
                        transform: 'scale(1.325)',
                        opacity: '0'
                    },
                    '100%': {
                        transform: 'scale(1.325)',
                        opacity: '0'
                    }
                }
            }
        }
    },
    plugins: [
        getPlugin('@tailwindcss/forms'),
        getPlugin('@tailwindcss/container-queries'),
        getPlugin('tailwindcss-animate'),
        getPlugin('tailwind-scrollbar')
    ]
} satisfies Config;

export default squaredUpTailwindConfig;
