import { ActionButton } from '@/components/Button';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { useRef, useState, type FC } from 'react';
import { useQueryClient } from 'react-query';
import SQLEditor from 'ui/editor/components/SQLEditor';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useDraftSQLContext } from '../../contexts/DraftSQLContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

export const AnalyticsQueryEditor: FC = () => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { datasets, setActivePreviewTab } = useDatasetContext();

    const { draftQuery, setDraftQuery, hasQueryChanged } = useDraftSQLContext();
    const [newSQL, setNewSQL] = useState(
        hasQueryChanged ? draftQuery ?? '' : tileConfig.dataStream?.dataSourceConfig?.sql || ''
    );

    const queryClient = useQueryClient();
    const [lastTriggered, setLastTriggered] = useState<number | undefined>();

    const { isFetching: isFetchingData, queryKey: previewQueryKey, refetch } = useDataStreamConfig(tileConfig);

    const { isFetching: isFetchingBustedData } = useDataStreamConfig(tileConfig, {
        enabled: Boolean(lastTriggered),
        ...(lastTriggered && {
            // Keep stale and cache time low so this data is quickly cleaned up
            staleTime: 0,
            cacheTime: 0,
            extraKeys: [lastTriggered],
            dataStreamOptions: { noCacheRead: true },
            onSettled: (data) => {
                if (previewQueryKey && data) {
                    queryClient.setQueryData(previewQueryKey, data);
                }

                // Clear lastTriggered to ensure we don't request cache-busted data when the user next hits execute
                setLastTriggered(undefined);
            }
        })
    });

    const timeoutRef = useRef<number>();
    const [isArtificialFetching, setIsArtificialFetching] = useState(false);

    const isFetching = isFetchingData || isFetchingBustedData || isArtificialFetching;
    const hasChangedSQL = tileConfig.dataStream?.dataSourceConfig?.sql !== newSQL;

    const handleSQLUpdate = (cacheBust?: boolean) => {
        if (newSQL) {
            setTileConfig({
                ...tileConfig,
                dataStream: {
                    ...tileConfig.dataStream,
                    dataSourceConfig: {
                        ...tileConfig.dataStream?.dataSourceConfig,
                        sql: draftQuery
                    }
                }
            });

            // Move to the data tab so the user can see the output
            setActivePreviewTab(datasets.length + 1);
        }

        if (cacheBust) {
            // Force the cacheBustedData to update
            setLastTriggered(Date.now());
        } else if (!hasChangedSQL) {
            // If the user is asking to execute with no changed and we may have cached data, do it anyway!
            refetch();

            // Artificially 'fetch' in the case there is client-cached data (we want to give the user feedback)
            setIsArtificialFetching(true);
            timeoutRef.current = window.setTimeout(() => setIsArtificialFetching(false), 1250);
        }
    };

    const storeSQL = (currentSQL: string) => {
        setDraftQuery(currentSQL);
    };

    return (
        <div className='flex flex-col flex-1 h-full min-w-0 pl-6 pr-5 space-y-4 py-7'>
            <div className='flex-1 min-h-0'>
                <SQLEditor
                    content={newSQL}
                    onValidUpdatedContent={(query) => {
                        setNewSQL(query || '');
                        storeSQL(query || '');
                    }}
                />
            </div>
            <div className='flex items-center flex-shrink-0 pl-4 pr-4 space'>
                <div className='inline-flex items-center mr-4 space-x-2 text-sm'>
                    <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                    <p>
                        Table names, column names, and values are case sensitive and may need to be enclosed with{' '}
                        <code>[]</code>.{' '}
                        <a
                            className='text-textLink'
                            href='https://squaredup.com/cloud/analytics/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </p>
                </div>

                <div className='flex gap-4 ml-auto w-fit'>
                    <ActionButton
                        onClick={() => handleSQLUpdate()}
                        portal='dialogContent'
                        disabled={isFetching}
                        icon={isFetching && <LoadingSpinner size={16} />}
                        actions={[
                            {
                                label: 'Force refresh',
                                onSelect: () => handleSQLUpdate(true)
                            }
                        ]}
                    >
                        Execute
                    </ActionButton>
                </div>
            </div>
        </div>
    );
};
