import { cn } from '@/lib/cn';
import { faFireFlameCurved, faHistory } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { TruncatedText } from 'components/TruncatedText';
import Tooltip from 'components/tooltip/Tooltip';
import { useAppContext } from 'contexts/AppContext';
import trackEvent from 'lib/analytics';
import { useFlag } from 'lib/useFlag';
import { omit } from 'lodash';
import PluginIcon from 'pages/scope/PluginIcon';
import { Dispatch, SetStateAction } from 'react';
import { useQueryClient } from 'react-query';
import { DATA_STREAM_DEFINITION } from 'services/DataStreamDefinitionService';
import { useTileEditorDataStreamFilterContext } from '../../contexts/TileEditorDataStreamFilterContext';
import { DataStreamListItem } from '../components/DataStreamListItem';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { DataStreamOptionTags } from './DataStreamOptionTags';
import { DataStreamMatchTypeDisplay } from './DataStreamTypeDisplay';

interface DataStreamOptionProps {
    dataStream: DataStreamFilterOption;
    recent?: boolean;
    config: DataStreamBaseTileConfig;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
    selectedTags: string[];
    toggleTag: (tag: string) => void;
}

const recentFeaturedIcon = (recent: boolean, featured: boolean) => {
    if (recent) {
        return () => (
            <Tooltip title='Recently used'>
                <FontAwesomeIcon icon={faHistory} aria-label='Recent icon' />
            </Tooltip>
        );
    }

    if (featured) {
        return () => (
            <Tooltip title='Featured'>
                <FontAwesomeIcon icon={faFireFlameCurved} aria-label='Featured icon' />
            </Tooltip>
        );
    }

    return () => <></>;
};

export const DataStreamOption: React.FC<DataStreamOptionProps> = ({
    dataStream,
    recent = false,
    config,
    setConfig,
    selectedTags,
    toggleTag
}) => {
    const queryClient = useQueryClient();

    const featured = dataStream.definition.featured ?? false;
    const dataStreamTagsFlagEnabled = useFlag('dataStreamTags');

    const { currentWorkspaceID } = useAppContext();
    const { selectedScopeId } = useTileEditorDataStreamFilterContext();

    const isSelected = dataStream.id === config?.dataStream?.id;

    const onSaveDataStreamSelection = ({ id, definition }: DataStreamFilterOption) => {
        const isSelectingDifferentDataStream = config.dataStream?.id !== id;

        // Cache any data stream related data to ensure the tile editor immediately reflect the current state
        queryClient.setQueryData([DATA_STREAM_DEFINITION, id], dataStream);

        setConfig({
            ...config,
            dataStream: {
                ...omit(config.dataStream, 'pluginConfigId'),
                dataSourceConfig: definition.presetOf ? definition.dataSourceConfig : undefined,
                id,
                name: definition?.name,
                group: undefined,
                sort: undefined,
                filter: undefined
            },
            // Reset scope and visualisation if selecting a new data stream
            ...(isSelectingDifferentDataStream && {
                visualisation: undefined
            }),
            // If there is a scope filter we want to carry it through to the next step
            scope: selectedScopeId
                ? {
                      workspace: currentWorkspaceID!,
                      scope: selectedScopeId
                  }
                : undefined,
            timeframe: dataStream.definition.defaultTimeframe === 'none' ? 'none' : undefined
        });
    };

    const onSelectDataStream = () => {
        trackEvent('Data Stream Selected', {
            name: dataStream.displayName,
            plugin: dataStream.pluginName,
            configurable: Boolean(dataStream.template),
            configured: Boolean(dataStream.sourceTemplate)
        });
        return onSaveDataStreamSelection(dataStream);
    };

    const Icon = recentFeaturedIcon(recent, featured);
    const displayName =
        dataStream.presetParentDataStream == null ? (
            dataStream.displayName
        ) : (
            <>
                <span
                    className={cn('text-tileEditorPresetCategoryText', {
                        'text-tileEditorPresetCategoryTextActive': isSelected
                    })}
                >
                    {dataStream.presetParentDataStream.displayName} /{' '}
                </span>
                {dataStream.displayName}
            </>
        );

    return (
        <DataStreamListItem
            key={dataStream.id}
            className={cn(
                'grid grid-cols-[1rem,minmax(7rem,_1fr),minmax(4rem,_1fr),minmax(8rem,_2fr),1rem] min-w-fit w-full gap-6',
                {
                    'grid-cols-[1rem,minmax(7rem,_1fr),minmax(4rem,_1fr),minmax(8rem,_2fr),1rem]':
                        dataStreamTagsFlagEnabled,
                    'grid-cols-[1rem,minmax(7rem,_1fr),minmax(7rem,_2fr),minmax(5rem,_1.2fr),1rem]':
                        !dataStreamTagsFlagEnabled
                }
            )}
            isSelected={isSelected}
            onClick={onSelectDataStream}
            data-testid='dataStreamOption'
            aria-selected={isSelected}
        >
            <span className='flex items-center space-x-3'>
                <span className='h-[15px] w-[15px] flex-shrink-0'>
                    <Tooltip title={dataStream.pluginName}>
                        <PluginIcon pluginName={dataStream.pluginName} />
                    </Tooltip>
                </span>
            </span>

            <div className='flex items-center'>
                <TruncatedText
                    title={dataStream.displayName}
                    className={cn('flex-1 min-w-0 text-textSecondary font-semibold', {
                        'text-textPrimary': isSelected
                    })}
                >
                    {displayName}
                </TruncatedText>
            </div>

            <div className='flex items-center'>
                <TruncatedText title={dataStream.description ?? ''} className='flex-1 min-w-0'>
                    {dataStream.description}
                </TruncatedText>
            </div>

            <div className='flex items-center'>
                {dataStreamTagsFlagEnabled ? (
                    <DataStreamOptionTags
                        tags={dataStream.definition.tags ?? []}
                        isDataStreamSelected={isSelected}
                        selectedTags={selectedTags}
                        toggleTag={toggleTag}
                    />
                ) : (
                    <DataStreamMatchTypeDisplay matchTypes={dataStream.definition.matchesTypes} />
                )}
            </div>

            <span className='flex items-center justify-center'>
                <Icon />
            </span>
        </DataStreamListItem>
    );
};
