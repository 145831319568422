import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { KPIType } from 'dynamo-wrapper';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { FC } from 'react';

interface KPITypeTableProps {
    kpiTypes: KPIType[];
    onEdit: (kpiType: KPIType) => void;
    onDelete: (kpiType: KPIType) => void;
    canEdit: (kpiType: KPIType) => boolean | undefined;
    canDelete: (kpiType: KPIType) => boolean | undefined;
}

const KPITypesApplicationTable = ApplicationTable<KPIType, string>();

const KPITypesTable: FC<KPITypeTableProps> = ({ kpiTypes, onEdit, onDelete, canEdit, canDelete }) => {
    const columnHelper = createColumnHelper<KPIType>();

    const columns: ColumnDef<KPIType, string>[] = [
        columnHelper.accessor((row) => row.displayName, {
            id: 'name',
            header: 'Name',
            cell: ({ getValue }) => {
                return <p>{getValue()}</p>;
            },
            size: 300
        })
    ];

    const actions: Action[] = [
        {
            visible: canEdit,
            action: onEdit,
            icon: faPencil,
            dataTestId: 'editWorkspaceButton',
            tooltip: 'Edit workspace'
        },
        {
            visible: canDelete,
            action: onDelete,
            icon: faTrash,
            dataTestId: 'deleteWorkspaceButton',
            tooltip: 'Delete workspace'
        }
    ];

    return (
        <div className='flex flex-col flex-1 w-full h-full min-h-0'>
            <KPITypesApplicationTable
                config={{
                    actions,
                    noDataMessage: 'There are no KPI types configured.'
                }}
                columns={columns}
                data={kpiTypes}
            />
        </div>
    );
};

export default KPITypesTable;
