import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';
import { useCallback } from 'react';
import { SettingsTemplate } from '../SettingsTemplate';
import KPITypeDeleteModal from './KPITypeDeleteModal';
import KPITypeModal from './KPITypeModal';
import KPITypesTable from './KPITypesTable';
import { useKPITypes } from './useKPITypes';
import useKPITypeSelection from './useKPITypeSelection';

function KPITypes() {
    const { isLoading, types, isFeatureAvailable, invalidate } = useKPITypes();
    const { selectedType, addEditModal, deleteModal, handleEdit, handleDelete, resetSelection } = useKPITypeSelection();

    const handleClose = useCallback(async () => {
        invalidate();
        addEditModal.close();
        deleteModal.close();
        resetSelection();
    }, [addEditModal, deleteModal, resetSelection, invalidate]);

    if (isLoading) {
        return (
            <SettingsTemplate title='KPI Types'>
                <div className='flex justify-center'>
                    <LoadingSpinner />
                </div>
            </SettingsTemplate>
        );
    }

    const kpiTypes = types ?? [];

    return (
        <SettingsTemplate
            title='KPI'
            description={
                isFeatureAvailable
                    ? 'KPIs can publish key metrics both within a workspace and also to aggregate at a higher level for complete oversight.'
                    : undefined
            }
            learnMoreLink='https://docs.squaredup.com/first-steps/kpis#adding-kpi-types'
        >
            <div className='space-y-4'>
                <FeatureUnavailableBanner featureKey='kpiTypes' className='mb-9' container='page' title='KPIs' />

                <Button onClick={addEditModal.open} data-testid='addKPIType' disabled={!isFeatureAvailable}>
                    Add KPI type
                </Button>

                <div className='flex flex-col min-h-0 mt-4 mb-8'>
                    <KPITypesTable
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        kpiTypes={kpiTypes}
                        canEdit={() => isFeatureAvailable}
                        canDelete={() => true}
                    />
                </div>

                {deleteModal.isOpen && <KPITypeDeleteModal type={selectedType!} close={handleClose} />}

                {addEditModal.isOpen && (
                    <KPITypeModal type={selectedType} close={handleClose} existingTypes={kpiTypes} />
                )}
            </div>
        </SettingsTemplate>
    );
}

export default KPITypes;
